import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import imgCover from "src/assets/img/save-ibuku/img_save_ibuku-cover.jpg"
import imgLogo from "src/assets/img/save-ibuku/img_logo.png"

export const SaveIbukuCover = () => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="sc-save_ibuku-cover" ref={trigger}>
      <div className="img-wrapper">
        <img src={imgCover} className="img-fluid img-cover" alt="cover" />
      </div>
      <div className="container h-100">
        <div className="row row-5 h-100">
          <div className="col-md-6 col-body">
            <div>
              <img
                src={imgLogo}
                alt="Save Ibuku"
                className={`img-fluid ${anim(1)}`}
              />
            </div>
            <p className={`${anim(2)}`}>
              Bergabunglah dalam Kampanye #SaveIbuku dan Lindungilah Ibu yang
              Kamu Cintai
            </p>
            <p className={`${anim(3)}`}>
              Dapatkan Rewards hingga Senilai <b>Rp10.000.000</b> Dengan
              menyelamatkan semakin banyak Ibu
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
