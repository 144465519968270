import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"
import { Button } from "components/anti/buttons/buttons"

import { Slider } from "components/anti/slider/slider"

import imgFact from "src/assets/img/save-ibuku/img_save-ibuku-fact.jpg"
import imgFact2 from "src/assets/img/save-ibuku/img_save-ibuku-fact-2.jpg"
import imgFact3 from "src/assets/img/save-ibuku/img_save-ibuku-fact-3.jpg"

export const SaveIbukuFact = () => {
  const [trigger, anim] = useScrollAnim()

  const listSlider = [
    {
      title:
        "1 dari 8 Perempuan Penting Dalam Hidupmu Memiliki Risiko Terkena Kanker Payudara.",
      text:
        "Source: Global Observatory Cancer, 2020 & WHO, 2020; Mammography Screening and Risk of Breast Cancer Death Study; Otto et al, 2012 ; BPS 2020 ; Breast Cancer Facts and Statistics Breastcancer.org",
      img: imgFact,
    },
    {
      title:
        "Data Globocan, 2020: Tercatat ada 68.858 kasus di Indonesia dengan jumlah kematian mencapai 22.000 jiwa",
      text:
        "Source: Global Observatory Cancer, 2020 & WHO, 2020; Mammography Screening and Risk of Breast Cancer Death Study; Otto et al, 2012 ; BPS 2020 ; Breast Cancer Facts and Statistics Breastcancer.org",
      img: imgFact2,
    },
    {
      title:
        "Risiko ini bisa dicegah. Tingkat kelangsungan hidup dalam 5 tahun terakhir adalah 90.3% melalui tindakan pencegahan",
      text:
        "Source: Global Observatory Cancer, 2020 & WHO, 2020; Mammography Screening and Risk of Breast Cancer Death Study; Otto et al, 2012 ; BPS 2020 ; Breast Cancer Facts and Statistics Breastcancer.org",
      img: imgFact3,
    },
  ]

  return (
    <section className="sc-save_ibuku-fact" ref={trigger}>
      <div className="container px-sm-down-0">
        <div className="row row-0">
          <div className="col-md-6 col-left py-5 px-sm-down-3">
            <h2 className={`${anim(1)}`}>Beli Paket Mammogram Sendiri</h2>
            <p className={`${anim(2)}`}>
              Dapatkan penawaran paket khusus member dimana kamu tidak hanya
              mendapatkan pemeriksaan tetapi juga layanan berkelanjutan dari
              Aman Concierge untuk semua kebutuhanmu.
            </p>
            <Button variant="primary" link="/mammogram">
              Beli Sekarang
            </Button>
          </div>
          <div className="col-md-6 col-right">
            <div className="header-slider px-3 px-md-5 py-4">
              <h2 className="mb-0">
                Fakta Terkait <br /> Kanker Payudara
              </h2>
            </div>
            <Slider
              noGutter
              fade
              visibleInitial={false}
              visibleLgDown={false}
              visibleMdDown={false}
              visibleSmDown={false}
              arrowsInitial={false}
              arrowsXlDown={false}
              arrowsLgDown={false}
              arrowsMdDown={false}
              arrowsSmDown={false}
              dotsInitial={true}
              dotsXxlDown={true}
              dotsXlDown={true}
              dotsLgDown={true}
              dotsMdDown={true}
              dotsSmDown={true}
              showInitial={1}
              showXlDown={1}
              showLgDown={1}
              showMdDown={1}
              showSmDown={1}
              dots
              className="fact-slider"
            >
              {listSlider.map((data, i) => {
                return (
                  <div className="slider-item" key={i}>
                    <img src={data.img} alt="fact" className="img-fluid" />
                    <div className="slider-body px-5 py-main">
                      <h5 className="mb-4">{data.title}</h5>
                      <p className="slider-text mb-0">
                        Source: Global Observatory Cancer, 2020 & WHO, 2020;
                        Mammography Screening and Risk of Breast Cancer Death
                        Study; Otto et al, 2012 ; BPS 2020 ; Breast Cancer Facts
                        and Statistics Breastcancer.org
                      </p>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
