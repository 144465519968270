import React from "react"
import Seo from "components/seo"
import Layout from "components/layout"

import { SaveIbukuCover } from "./cover"
import { SaveIbukuReward } from "./reward"
import { SaveIbukuFact } from "./fact"

const SaveIbukuPage = () => {
  return (
    <Layout noCover>
      <Seo title="Save Ibuku" />
      <SaveIbukuCover />
      <SaveIbukuReward />
      <SaveIbukuFact />
    </Layout>
  )
}

export default SaveIbukuPage
