import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"
import { Button } from "components/anti/buttons/buttons"

import imgCover from "src/assets/img/save-ibuku/img_save_ibuku-reward.jpg"

export const SaveIbukuReward = () => {
  const [trigger, anim] = useScrollAnim()

  return (
    <section className="sc-save_ibuku-reward" ref={trigger}>
      <div className="img-wrapper">
        <img src={imgCover} className="img-fluid img-cover" alt="cover" />
      </div>
      <div className="container py-main h-100">
        <div className="row row-5 h-100">
          <div className="offset-md-6 col-md-6 col-body">
            <h2 className={`${anim(1)}`}>Reward untuk Teman Aman:</h2>
            <p className={`${anim(2)}`}>
              Setiap 1 Ibu yang berhasil melakukan mammogram, kamu akan
              mendapatkan Aman Voucher senilai <b>Rp100.000</b> untuk
              berbelanja, traktir teman/pacar, dan masih banyak lainnya.
            </p>
            <p className={`${anim(2)}`}>
              Semakin banyak Ibu yang kamu selamatkan, semakin besar reward yang
              kamu dapatkan.
            </p>
            <div className={`${anim(3)}`}>
              <Button
                variant="primary"
                // link="/save-ibuku/form"
                link="https://mailchi.mp/dompetaman/ori2-bergabung-jadi-referral"
              >
                Join The Force
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
